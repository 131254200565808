import React from 'react';
import styled from 'styled-components';
import * as AppActions from '../../../AppActions';

const F = AppActions.F;

function ArticleItem(props) {
  const {item, prebuilt, url, extraStyle} = props;

  if (!item) {
    return <Wrapper extraStyle={extraStyle} />;
  }

  return (
    <Wrapper
      extraStyle={extraStyle}
      href={url}
      onClick={(e) => {
        e.preventDefault();
        AppActions.navigate(url, prebuilt ? {loading: 360} : undefined);
      }}>
      <div className="frame">
        <img
          alt="article-img"
          src={item.cover || '../../images/empty-img.png'}
        />
      </div>
      <div className="info">
        <p>{new Date(item.created).toLocaleDateString().replace(/\//g, '-')}</p>
        <h3>{F(item, 'title')}</h3>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.a`
  display: block;
  margin: 0 auto;

  max-width: 360px;
  width: 100%;
  cursor: pointer;

  & > div.frame {
    overflow: hidden;
    border-radius: 15px;

    & > img {
      max-width: 360px;
      width: 100%;
      height: 240px;
      object-fit: cover;
      transition: all 200ms;

      &:hover {
        transform: scale(1.1);
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
      }

      @media screen and (max-width: ${768 - 1}px) {
        max-width: 320px;
        height: 213px;
      }
    }
  }

  & > div.info {
    padding: 10px;

    & > p {
      color: #aaa;
      font-size: 0.8rem;
    }

    & > h3 {
      font-size: 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }

  ${(props) => props.extraStyle || ''}
`;

export default ArticleItem;
