import React from 'react';
import styled, {keyframes} from 'styled-components';

function ProductItem(props) {
  const {product, onClick, mobile} = props;
  const [loaded, setLoaded] = React.useState(false);
  const soldout =
    product.stock_type !== 'always' &&
    product.stock_sold_amount >= product.stock_amount;

  const src = React.useMemo(() => {
    try {
      return product.images[0].expected_url;
    } catch (e) {
      return null;
    }
  }, [product.images]);

  return (
    <Wrapper loaded={loaded} mobile={mobile} onClick={onClick}>
      <div className="product-img">
        {!loaded && (
          <img
            className="loading"
            src={'/images/empty-img.png'}
            alt="product"
          />
        )}
        <img
          src={src}
          alt="product"
          loading="lazy"
          onLoad={() => {
            setLoaded(true);
          }}
        />
      </div>

      <div className="info">
        <h3>{product.name}</h3>
        <div style={{flex: 1}} />
        {soldout ? (
          <p
            style={{
              textAlign: 'right',
              color: 'var(--primaryColor)',
            }}>
            已售完
          </p>
        ) : (
          <p style={{textAlign: 'right'}}>
            {!!product.original_price && (
              <span
                style={{
                  textDecoration: 'line-through',
                  color: '#CBCBCB',
                  marginRight: 10,
                }}>
                ${product.original_price}
              </span>
            )}
            {!!product.price && (
              <span style={{fontSize: '1.2rem'}}>${product.price}</span>
            )}
          </p>
        )}
      </div>
    </Wrapper>
  );
}

const shaking = keyframes`
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(30deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-30deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

const Wrapper = styled.div`
  margin: 0 auto;
  width: ${(props) => (props.mobile ? '160px' : '200px')};
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: 300ms;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.05);
  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.2);
  }
  & > .info {
    display: flex;
    flex-direction: column;
    max-height: 120px;
    padding: 8px 10px;
    background-color: white;
    & > h3 {
      flex: 1;
      font-size: 14px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: break-spaces;
    }
    & > p {
      font-weight: bold;
    }
  }
  & > .product-img {
    position: relative;
    background-color: #fff;
    width: 100%;
    aspect-ratio: 1;

    & > .loading {
      position: absolute;
      top: 0;
      left: 0;
      animation: ${shaking} 1000ms linear infinite;

      visibility: ${(props) => (props.loaded ? 'hidden' : 'visibale')};
      filter: blur(8px);
      transition: visibility 0ms ease-out 500ms;
    }

    & > img {
      width: 100%;
      height: 100%;
      padding: 20px;
      object-fit: contain;

      opacity: ${(props) => (props.loaded ? 1 : 0)};
      transition: opacity 500ms ease-in 0ms;

      &:hover {
        transform: scale(1.2);
        transition: 200ms;
      }
    }
  }
`;

export default ProductItem;
